<ngx-spinner
    id="pf-spinner"
    bdColor="rgba(0,0,0,0.1)"
    size="medium"
    color="#5e72e4"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    zIndex="99999999"
></ngx-spinner>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<router-outlet></router-outlet>
