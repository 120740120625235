import { Injectable } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { LayoutComponent } from './layout.component';
// import { AuthGuard } from '../core/authentication/auth.guard';
import { SignUpGuardService } from '../auth/guard/signup-guard.service';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    constructor() { }

    static routes(routes: Routes): Route {
        return {
            path: '',
            component: LayoutComponent,
            children: routes,
            canActivate: [SignUpGuardService],
            // data: { reuse: true }
        };
    }
}
