import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { StorageService } from '../../services/storage.service';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root',
})
export class SignUpGuardService {

    public constructor(
        private router: Router,
        private authService: AuthService,
        private storage: StorageService,
    ) { }

    /**
     * It will check if user is authenticated or not.
     * Return true if authenticated otherwise false.
    */
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const authToken = this.authService.getToken();
        const type = this.storage.getItem('type');
        if (_.isEmpty(authToken) && _.isEqual(state.url, '/auth/login') === false && _.isEqual(state.url, '/auth/signup') === false && _.isEqual(state.url, '/auth/forgot-password') === false && _.startsWith(state.url, '/auth/signup?plan=') === false && _.startsWith(state.url, '/auth/signup?inviteId=') === false && _.startsWith(state.url, '/auth/login?ref=') === false && _.startsWith(state.url, '/auth/login?url=') === false && _.startsWith(state.url, '/auth/login?email=') === false) {
            // console.log('in first condition');
            this.router.navigate(['/', 'auth', 'login']);
            return false;
        }
        else if (_.isEmpty(authToken) === false && _.isEqual(state.url, '/auth/login') === true) {
            // console.log('in second condition');
            this.router.navigate(['/', 'user', 'dashboard']);
            return true;
        } else if (_.isEmpty(authToken) === false && _.isEqual(state.url, '/auth/signup') === true) {
            // console.log('in third condition');
            this.router.navigate(['/', 'user', 'dashboard']);
            return true;
        } else if (_.isEmpty(authToken) === false && _.isEqual(state.url, '/auth/forgot-password') === true) {
            // console.log('in forth condition');
            this.router.navigate(['/', 'user', 'dashboard']);
            return true;
        } else if (_.isEmpty(authToken) === true && _.startsWith(state.url, '/auth/signup?inviteId=') === true) {
            // console.log('in six condition');
            // Allow user to access register page when url has team id parameter
            return true;
        } else if (_.isEmpty(authToken) === false && _.isEmpty(type) === true && _.isEqual(state.url, '/auth/signup/step-2') === true) {
            // console.log('in seven condition');
            // Navigate user if already logged in
            this.router.navigate(['/', 'user', 'dashboard']);
            return true;
        } else if (_.isEmpty(authToken) === true && _.startsWith(state.url, '/auth/login?ref=') === true) {
            // console.log('in eight condition');
            return true;
        } else if (_.isEmpty(authToken) === true && _.startsWith(state.url, '/auth/login?email=') === true) {
            // console.log('in ninth condition');
            return true;
        } else if (_.isEmpty(authToken) === true && _.startsWith(state.url, '/auth/login?url=') === true) {
            // console.log('in eight condition');
            return true;
        }
        return true;
    }
}
