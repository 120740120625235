import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from '../../services/storage.service';
import { AuthService } from '../../auth/auth.service';
import { filter } from 'rxjs/operators';
import { EventService } from '../../event/event.service';

@Component({
	selector: 'app-aside',
	templateUrl: './aside.component.html',
	styleUrl: './aside.component.scss'
})
export class AsideComponent implements OnInit {
	isStripeConnected = true;
	activeImage: boolean = false;
	activateEngagement: boolean = false;
	activateAccountSettings: boolean = false;
	tempList: any[] = [];
	isEventEdit: boolean = false;
	eventData: any = null;


	constructor(
		private readonly router: Router,
		private readonly spinnerService: NgxSpinnerService,
		private activatedRoute: ActivatedRoute,
		private readonly authService: AuthService,
		private storageService: StorageService,
		private eventService: EventService
	) {
		this.router.events.subscribe((res: any) => {
			if (res?.url) {
				if (res?.url?.includes('engagement') && !res?.url?.includes('create')) {
					this.activateEngagement = true;
					this.activateAccountSettings = false;
				} else if (res?.url?.includes('account-setting')) {
					this.activateAccountSettings = true;
					this.activateEngagement = false;
				} else {
					this.activateAccountSettings = false;
					this.activateEngagement = false;
				}
			}
		});
	}


	ngOnInit(): void {
		// Check the initial route when the component initializes
		this.checkRoute(this.router.url);

		// Listen for route changes using router.events
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			this.checkRoute(event.url);
		});
	}

	// Function to check the route and extract query parameters or route parameters
	checkRoute(url: string) {
		if (url.includes('/event/create')) {
			const queryParams = this.activatedRoute.snapshot.queryParams;
			const id = queryParams['id'];
			const isEdit = queryParams['isEdit'];

			if (id && isEdit) {
				this.eventService.getEventById(id).subscribe({
					next: (res) => {
						if (res) {
							this.isEventEdit = true;
							this.eventData = res;
						}
					},
					error: (err) => {
						console.error('Error on aside component', err);
					}
				});
			}
		} else if (url.includes('/event/registration-report')) {
			const routeParams = this.activatedRoute.snapshot.params;
			const eventId = routeParams['eventId'];

			if (eventId) {
				this.eventService.getEventById(eventId).subscribe({
					next: (res) => {
						if (res) {
							this.isEventEdit = true;
							this.eventData = res;
						}
					},
					error: (err) => {
						console.error('Error on aside component', err);
					}
				});
			} else {
				this.isEventEdit = false;
				this.eventData = null;
			}
		} else {
			this.isEventEdit = false;
			this.eventData = null;
		}
	}



	navigateToRegistrationReport() {
		this.router.navigate(['/event/registration-report'], { queryParams: { eventId: this.eventData.id } });
	}

	navigate(routeName: string) {
		this.router.navigate([routeName], {
			queryParams: {}
		});
	}

	async logout() {
		this.spinnerService.show();
		this.authService.doLogout().toPromise().then(() => {
			this.storageService.removeItem('token');
			this.storageService.removeItem('viewUserId');
			this.storageService.removeItem('type');
			this.storageService.removeItem('refresh-token');
			this.spinnerService.hide();
			// error
			// heap.resetIdentity();
			return this.router.navigate(['/', 'auth', 'login']);
		}).catch((error) => {
			this.storageService.removeItem('token');
			this.storageService.removeItem('viewUserId');
			this.storageService.removeItem('type');
			this.storageService.removeItem('refresh-token');
			console.error(error);
			this.spinnerService.hide();
			return this.router.navigate(['/', 'auth', 'login']);
		});
	}

	isChildLinkActive(): boolean {
        // Check if any child link is active
        return this.router.isActive('/engagement', false) && !!this.activatedRoute.firstChild;
    }

	isAccountSettingsChildLinkActive(): boolean {
        // Check if any child link of Account Settings is active
        return this.router.isActive('/account-setting', false) && !!this.activatedRoute.firstChild;
    }
	toggleCollapse() {
		this.activeImage = !this.activeImage;

		this.activateEngagement = false;
		this.activateAccountSettings = false;
	}
	
	toggleEngagementCollapse() {
		this.activateEngagement = !this.activateEngagement;
        // Ensure other section is collapsed when toggling this one
		this.activeImage = false
		this.activateAccountSettings = false;
    }
	toggleAccountSettingsCollapse() {
		this.activateAccountSettings = !this.activateAccountSettings;
        // Ensure other section is collapsed when toggling this one
        this.activateEngagement = false;
		this.activeImage = false
    }

	editEvent(event: any) {
		return this.router.navigate(['/event/create'], {
			queryParams: {
				id: event.id,
				isEdit: true,
			}
		});
	}
}
