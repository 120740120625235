<div class="d-flex justify-content-center align-items-center mt-2" style="height: 82.1px;">
	<div class="d-flex justify-content-center align-items-center mt-2 cursor-pointer" [routerLink]="['/','event']">
		<img src="/assets/img/logos/logo.png" class="logo" alt="main_logo" />
		<div class="" style="margin-left: 6px;">
			<div class="text-uppercase fw-800 fs-36 text-black">Stubified</div>
			<div class="text-uppercase text-primary fs-9" style="margin-top: -13px;">For an amplified event experience
			</div>
		</div>
	</div>
</div>
<hr class="horizontal dark my-2" />
<div class="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
	<ul class="navbar-nav">
		<li class="nav-item">
			<a *ngIf="!isEventEdit" class="nav-link cursor-pointer nav-padding m-0" routerLink="/event"
				routerLinkActive="active" (click)="navigate('event')">
				<div
					class="icon icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
					<i class="fa-regular fa-calendar text-sm"></i>
				</div>
				<span class="nav-link-text ms-1" translate>Events</span>
			</a>

			<a *ngIf="isEventEdit" [ngClass]="isEventEdit ? 'active' : ''"
				class="nav-link cursor-pointer nav-padding m-0 togle" data-bs-toggle="collapse" href="#collapseExample2"
				role="button" aria-expanded="false" aria-controls="collapseExample" (click)="navigate('/event')"
				routerLink="/event">
				<div
					class="icon icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
					<i class="fa-regular fa-calendar text-sm"></i>
				</div>
				<span class="nav-link-text ms-1" translate>Events</span>
			</a>
			<div *ngIf="isEventEdit && eventData" class="collapse" [ngClass]="{'show': isEventEdit}" id="collapseExample2">
				<ul class="navbar-nav">
					<li class="nav-item" (click)="editEvent(eventData)">
						<a class="nav-link cursor-pointer nav-padding m-0 abc">
							<span class="px-5">{{eventData.name}}</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0 abc"
							routerLink="event/registration-report/{{eventData.id}}">
							<span class="px-5">Registration Reports</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0 abc" routerLink="/event/send-email">
							<span class="px-5">Send Email</span>
						</a>
					</li>
					<!-- <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 abc" [ngClass]="activateImage ? 'active' : ''" data-bs-toggle="collapse" (click)="toggleCollapse()" role="button" aria-expanded="false" aria-controls="collapseExample0">
                            <span class="px-5">My Images</span>
                        </a>

                        <div class="collapse" [ngClass]="{ 'show': activateImage}" id="collapseExample0">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link cursor-pointer nav-padding m-0 image-anchor" (click)="navigate('/')" routerLink="/" routerLinkActive="abc">
                                        <div class="icon icon-sm border-radius-md text-center d-flex align-items-center justify-content-center">
                                            <i class="fa-solid fa-folder opacity-10 image-icon"></i>
                                        </div>
                                        <span class="mr--7">{{eventData.name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li> -->

					<li class="nav-item">
						<a [ngClass]="activeImage ? 'active' : ''" class="nav-link cursor-pointer nav-padding m-0"
							(click)="toggleCollapse()" data-bs-toggle="collapse" role="button" aria-expanded="false"
							aria-controls="collapseExample3">
							<span class="nav-link-text  px-5">My Images</span>
						</a>
						<div class="collapse" [ngClass]="{ 'show': activeImage}" id="collapseExample3">
							<ul class="navbar-nav">
								<li class="nav-item">
									<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate('/event/image-manager')"
										routerLink="/event/image-manager" routerLinkActive="abc">
										<div
											class="icon icon-sm border-radius-md text-center d-flex align-items-center justify-content-center">
											<i class="fa-solid fa-folder opacity-10 image-icon"></i>
										</div>
										<span class="text-truncate">{{eventData.name}}</span>
									</a>
								</li>
							</ul>
						</div>
					</li>


					<!-- <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 abc custom-active " [ngClass]="{ 'active': !isCollapsed} " data-bs-toggle="collapse " (click)="isCollapsed=! isCollapsed " role="button ">
                            <span class="px-5 ">My Images</span>
                        </a>
                        <div class="collapse " [ngbCollapse]="isCollapsed " id="collapseExample0 ">
                            <ul class="navbar-nav ">
                                <li class="nav-item ">
                                    <a class="nav-link cursor-pointer nav-padding m-0 image-anchor " (click)="navigate( '/') " routerLink="/ " routerLinkActive="abc ">
                                        <div class="icon icon-sm border-radius-md text-center d-flex align-items-center justify-content-center ">
                                            <i class="fa-solid fa-folder opacity-10 image-icon "></i>
                                        </div>
                                        <span class="mr--7 ">{{eventData.name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li> -->
				</ul>
			</div>
		</li>
		<div class="radial-gradient w-100"></div>
		<!-- <li class="nav-item ">
            <a [ngClass]="activateEngagement ? 'active' : '' " class="nav-link cursor-pointer nav-padding m-0 " data-bs-toggle="collapse " href="#collapseExample " role="button " aria-expanded="false " aria-controls="collapseExample ">
                <div class="icon icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center ">
                    <i class="fa-solid fa-gear text-sm opacity-10 "></i>
                </div>
                <span class="nav-link-text ms-1 ">Engagement</span>
            </a>
            <div class="collapse " [ngClass]="{ 'show': activateEngagement} " id="collapseExample ">
                <ul class="navbar-nav ">
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/engagement/venue') " routerLink="/engagement/venue " routerLinkActive="abc ">
                            <span class="px-5 ">Venue</span>
                        </a>
                    </li>
                    <div class="radial-gradient w-100 "></div>
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/engagement/rooms') " routerLink="/engagement/rooms " routerLinkActive="abc ">
                            <span class="px-5 ">Rooms</span>
                        </a>
                    </li>
                    <div class="radial-gradient w-100 "></div>
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/engagement/contacts') " routerLink="/engagement/contacts " routerLinkActive="abc ">
                            <span class="px-5 ">Contacts</span>
                        </a>
                    </li>
                    <div class="radial-gradient w-100 "></div>
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/engagement/sponsors') " routerLink="/engagement/sponsors " routerLinkActive="abc ">
                            <span class="px-5 ">Sponsors</span>
                        </a>
                    </li>
                    <div class="radial-gradient w-100 "></div>
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/engagement/company') " routerLink="/engagement/company " routerLinkActive="abc ">
                            <span class="px-5 ">Company</span>
                        </a>
                    </li>
                    <div class="radial-gradient w-100 "></div>
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/engagement/questions') " routerLink="/engagement/questions " routerLinkActive="abc ">
                            <span class="px-5 ">Questions</span>
                        </a>
                    </li>
                    <div class="radial-gradient w-100 "></div>
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/engagement/email-template') " routerLink="/engagement/email-template " routerLinkActive="abc ">
                            <span class="px-5 ">Email Templates</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <div class="radial-gradient w-100 "></div>
        <li class="nav-item ">
            <a [ngClass]="activateAccountSettings ? 'active' : '' " class="nav-link cursor-pointer nav-padding m-0 " data-bs-toggle="collapse " href="#collapseExample1 " role="button " aria-expanded="false " aria-controls="collapseExample1 ">
                <div class="icon icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center ">
                    <i class="fa-solid fa-gear text-sm opacity-10 "></i>
                </div>
                <span class="nav-link-text ms-1 ">Account Settings</span>
            </a>
            <div class="collapse " [ngClass]="{ 'show': activateAccountSettings} " id="collapseExample1 ">
                <ul class="navbar-nav ">
                    <li class="nav-item ">
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/account-setting/event-category') " routerLink="/account-setting/event-category " routerLinkActive="abc ">
                            <span class="px-5 ">Event category</span>
                        </a>
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/account-setting/contact-type') " routerLink="/account-setting/contact-type " routerLinkActive="abc ">
                            <span class="px-5 ">Contact Type</span>
                        </a>
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/account-setting/sponsorship-type') " routerLink="/account-setting/sponsorship-type " routerLinkActive="abc ">
                            <span class="px-5 ">Sponsorship Type</span>
                        </a>
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/account-setting/vendor-type') " routerLink="/account-setting/vendor-type " routerLinkActive="abc ">
                            <span class="px-5 ">Vendor Type</span>
                        </a>
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/account-setting/company-type') " routerLink="/account-setting/company-type " routerLinkActive="abc ">
                            <span class="px-5 ">Company Type</span>
                        </a>
                        <a class="nav-link cursor-pointer nav-padding m-0 " (click)="navigate( '/account-setting/inventory') " routerLink="/account-setting/inventory " routerLinkActive="abc ">
                            <span class="px-5 ">Inventory</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li> -->

		<!-- Engagement section -->
		<li class="nav-item">
			<a [ngClass]="(activateEngagement || isChildLinkActive()) ? 'active' : ''"
				class="nav-link cursor-pointer nav-padding m-0" (click)="toggleEngagementCollapse()"
				data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample">
				<!-- Your toggle button content -->
				<div
					class="icon icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
					<i class="fa-solid fa-gear text-sm opacity-10"></i>
				</div>
				<span class="nav-link-text ms-1">Engagement</span>
			</a>
			<div class="collapse " [ngClass]="{ 'show': activateEngagement}" id="collapseExample">
				<!-- Your engagement section content -->
				<ul class="navbar-nav">
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/venue')"
							routerLink="/engagement/venue" routerLinkActive="abc">
							<span class="px-5">Venue</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/rooms')"
							routerLink="/engagement/rooms" routerLinkActive="abc">
							<span class="px-5">Rooms</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/contacts')"
							routerLink="/engagement/contacts" routerLinkActive="abc">
							<span class="px-5">Contacts</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/sponsors')"
							routerLink="/engagement/sponsors" routerLinkActive="abc">
							<span class="px-5">Sponsors</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/company')"
							routerLink="/engagement/company" routerLinkActive="abc">
							<span class="px-5">Company</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/questions')"
							routerLink="/engagement/questions" routerLinkActive="abc">
							<span class="px-5">Questions</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/email-template')"
							routerLink="/engagement/email-template" routerLinkActive="abc">
							<span class="px-5">Email Templates</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/engagement/waitlist')"
							routerLink="/engagement/waitlist" routerLinkActive="abc">
							<span class="px-5">Waitlist</span>
						</a>
					</li>
				</ul>
			</div>
		</li>
		<div class="radial-gradient w-100"></div>

		<!-- Account Settings section -->
		<li class="nav-item">
			<a [ngClass]="activateAccountSettings || isAccountSettingsChildLinkActive() ? 'active' : ''"
				class="nav-link cursor-pointer nav-padding m-0" (click)="toggleAccountSettingsCollapse()"
				data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample1">
				<!-- Your toggle button content -->
				<div
					class="icon icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
					<i class="fa-solid fa-gear text-sm opacity-10"></i>
				</div>
				<span class="nav-link-text ms-1">Account Settings</span>
			</a>
			<div class="collapse" [ngClass]="{ 'show': activateAccountSettings}" id="collapseExample1">
				<!-- Your account settings section content -->
				<ul class="navbar-nav">
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0"
							(click)="navigate( '/account-setting/event-category')" routerLink="/account-setting/event-category"
							routerLinkActive="abc">
							<span class="px-5 ">Event category</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0"
							(click)="navigate( '/account-setting/contact-type')" routerLink="/account-setting/contact-type"
							routerLinkActive="abc">
							<span class="px-5 ">Contact Type</span>
						</a>
					</li>
					<div class="radial-gradient w-100"
						style="background: radial-gradient( circle, rgba(23, 0, 69, 0.354) 0%, rgba(255, 255, 255, 1) 70%);">
					</div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0"
							(click)="navigate( '/account-setting/sponsorship-type')"
							routerLink="/account-setting/sponsorship-type" routerLinkActive="abc">
							<span class="px-5 ">Sponsorship Type</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/account-setting/vendor-type')"
							routerLink="/account-setting/vendor-type" routerLinkActive="abc">
							<span class="px-5 ">Vendor Type</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0"
							(click)="navigate( '/account-setting/company-type')" routerLink="/account-setting/company-type"
							routerLinkActive="abc">
							<span class="px-5 ">Company Type</span>
						</a>
					</li>
					<div class="radial-gradient w-100"></div>
					<li class="nav-item">
						<a class="nav-link cursor-pointer nav-padding m-0" (click)="navigate( '/account-setting/inventory')"
							routerLink="/account-setting/inventory" routerLinkActive="abc">
							<span class="px-5 ">Inventory</span>
						</a>
					</li>
				</ul>
			</div>
		</li>
		<div class="radial-gradient w-100"></div>


	</ul>
</div>