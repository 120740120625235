import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { AsideComponent } from './aside/aside.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LanguageDropdownComponent } from '../components/language-dropdown/language-dropdown.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        LanguageDropdownComponent,
        NgbModule,
        TranslateModule,
    ],
    declarations: [
        LayoutComponent,
        AsideComponent,
        NavbarComponent,
        FooterComponent
    ],
    providers: [
        LocalStorageService,
    ]
})
export class LayoutModule { }