<!-- Navbar -->
<nav class="navbar navbar-main navbar-expand-lg bottom-shadow nav-padding bg-white" id="navbarBlur" data-scroll="false">
	<div class="container-fluid px-3">
		<div class="collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" id="navbar">
			<div class="input-group rounded-pill">
				<span class="input-group-text bg-gray-100 border-0 px-3"><i
						class="fa-solid fa-magnifying-glass text-secondary"></i></span>
				<input class="form-control bg-gray-100 border-0" style="height: 48px;" placeholder="Search" type="text">
			</div>
			<div ngbDropdown placement="bottom-right">
				<div class="d-flex justify-content-center align-items-center cursor-pointer" role="button"
					ngbDropdownToggle>
					<div style="margin-left: 15px; margin-right: 15px;">
						<img src="/assets/img/logos/logo.png" style="height: 20px;" alt="image">
					</div>
					<div style="max-width: 140px; white-space: nowrap;">
						<div class="user-name fw-700 fs-16">{{userName}}</div>
						<div class="account-name fw-700 fs-16">{{accountName}}</div>
					</div>
				</div>
				<div class="dropdown-menu-arrow dropdown-menu-right pt-4 ml-1" ngbDropdownMenu>
					<a [routerLink]="['']" class="dropdown-item">
						<i class="fa-solid fa-user"></i>
						<span>Profile</span>
					</a>
					<a class="dropdown-item" (click)="logout()">
						<i class="fa-solid fa-arrow-right-from-bracket"></i>
						<span>Logout</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</nav>
<!-- End Navbar -->