import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LayoutService } from './layout/layout.service';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module')
			.then(m => m.AuthModule),
	},
	LayoutService.routes([
		{
			path: '',
			loadChildren: () => import('./profile/profile.module')
				.then(m => m.ProfileModule),
		},
		{
			path: 'event',
			loadChildren: () => import('./event/event.module')
				.then(m => m.EventModule),
		},
		{
			path: 'engagement',
			loadChildren: () => import('./engagement/engagement.module')
				.then(m => m.EngagementModule),
		},
		{
			path: 'account-setting',
			loadChildren: () => import('./account-settings/account-settings.module')
				.then(m => m.AccountSettingsModule),
		}
	]),
	{
		path: 'unsubscribe',
		loadChildren: () => import('./unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
	}
	// { path: '**', redirectTo: 'auth/login' },
];

const config: ExtraOptions = {
	useHash: false
};

@NgModule({
	imports: [
		RouterModule.forRoot(routes, config),
	],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule { }
