import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@app/core';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './auth/interceptor/token.interceptor';
import { LayoutModule } from './layout/layout.module';
import { WindowService } from './services/window.service';
import { ToastsContainer } from './shared/toast/toast-container.component';
import { ToastService } from './shared/toast/toast.services';

const config: SocketIoConfig = {
	url: environment.SOCKET.URL,
	options: {
		autoConnect: true,
		withCredentials: false,
		reconnection: true,
		reconnectionAttempts: environment.SOCKET.RECONNECTION_ATTEMPTS,
		transports: ['websocket', 'polling']

	}
};

@NgModule({
	imports: [
		BrowserModule,
		NgxWebstorageModule.forRoot(),
		SocketIoModule.forRoot(config),
		BrowserAnimationsModule,
		HttpClientModule,
		TranslateModule.forRoot(),
		CoreModule,
		NgxSpinnerModule,
		RouterModule,
		LayoutModule,
		AbilityModule,
		AppRoutingModule,
		ToastsContainer,
		AngularFireMessagingModule,
	],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	providers: [
		ToastService,
		WindowService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		{
			provide: Ability, useValue: new Ability()
		},
		{ provide: PureAbility, useExisting: Ability },
	]
})
export class AppModule { }
