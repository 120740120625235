<footer class="footer pt-3">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-lg-between">
            <div class="text-center p-2">
                &#169; 2023 Powered by
                <a href="/"><b>Stubified</b></a>
            </div>
        </div>
    </div>
</footer>
