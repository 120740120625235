import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { StorageService } from '../../services/storage.service';
import { ToastService } from '../../shared/toast/toast.services';
import { AuthService } from '../auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    public currentPage: string;

    public constructor(private authService: AuthService,
        private storageService: StorageService,
        private toastService: ToastService,
        private spinner: SpinnerService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!navigator.onLine) {
            this.toastService.error('ERROR_TO_REACH_OUT');
            this.spinner.hide();
        }
        if (request.url === 'https://fcm.googleapis.com/fcm/send') {
            request = request.clone({
                setHeaders: {
                    Authorization: `key=${environment.FCM.SERVER_KEY}`,
                    'Content-type': 'application/json'
                }
            });
        } else if (request.url.includes('/users/refresh')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.getRefreshToken()}`
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.getToken()}`,
                },
            });
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // heap.track('Error On Token-Interceptor interceptor', error);
                if (error && error.status === 401 && error.error.message === 'Unauthorized') {
                    return this.handle401Error(request, next, error);
                } else if (error && error.error && (error.error.message === 'USER_IS_NOT_ACTIVE' || error.error.message === 'USER_NOT_FOUND') && error.status === 403) {
                    from(this.handle401Error(request, next, error));
                } else if (error && error.status === 403 && error.error.message === 'USER_NOT_VERIFIED') {
                    this.router.navigateByUrl('/auth/thank-you');
                    this.spinner.hide();
                    return throwError(error.error);
                } else if (error && error.error && error.error.message === 'ACCESS_NOT_GRANTED' &&
                    error.error.statusCode === 401) {
                    from(this.handle401Error(request, next, error));
                } else if (error && error.status === 502 && error.error.message === 'SERVER_UNDER_MAINTENANCE') {
                    from(this.handle401Error(request, next, error));
                } else if (error && error.status === 401 && error.error.message === 'SESSION_EXPIRE') {
                    from(this.handle401Error(request, next, error));
                } else if (error && (error.status === 503 || error.status === 0)) {
                    from(this.handle401Error(request, next, error));
                }
                if (error.error) {
                    try {
                        const parseError = JSON.parse(error.error);
                        return throwError(parseError);
                    }
                    catch {
                        return throwError(error.error);
                    }
                }
                return throwError(error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (error && error.status === 502 && error.error.message === 'SERVER_UNDER_MAINTENANCE' || error && error.status === 401 && error.error.message === 'SESSION_EXPIRE') {
            this.storageService.removeItem('token');
            this.storageService.removeItem('viewUserId');
            this.storageService.removeItem('type');
            this.storageService.removeItem('refresh-token');
            this.toastService.error(error.error.message);
            this.router.navigate(['/', 'auth', 'login']);
            throw error;
        } else if (error && error.status === 403 && (error.error.message === 'USER_IS_NOT_ACTIVE' || error.error.message === 'USER_NOT_FOUND')) {
            this.storageService.removeItem('token');
            this.storageService.removeItem('viewUserId');
            this.storageService.removeItem('type');
            this.storageService.removeItem('refresh-token');
            this.toastService.error(error.error.message);
            this.router.navigate(['/', 'auth', 'login']);
            throw error;
        }
        if (error.url.includes('/users/refresh')) {
            this.spinner.show();
            this.currentPage = this.route.snapshot['_routerState'].url;
            this.storageService.removeItem('token');
            this.storageService.removeItem('viewUserId');
            this.storageService.removeItem('type');
            this.toastService.error('SESSION_EXPIRE');
            from(of('waitTime').pipe(delay(2000))).subscribe(() => {
                this.router.navigate(['/', 'auth', 'login'], { queryParams: { url: this.currentPage } });
                this.spinner.hide();

            });
            return next.handle(request);
        }
        else if (error && error.status === 503) {
            this.toastService.error('ERROR_TO_REACH_OUT');
            this.spinner.hide();
        }

        return this.authService.refreshToken().pipe(
            switchMap((newToken: any) => {
                this.authService.setToken(newToken.token);
                const updatedRequest = this.addAuthorizationHeader(request, newToken.token);
                return next.handle(updatedRequest); // Retry the request with the updated token
            }),
            catchError((refreshError: any) => {
                this.spinner.show();
                this.currentPage = this.route.snapshot['_routerState'].url;
                this.storageService.removeItem('accessToken');
                this.storageService.removeItem('viewUserId');
                this.storageService.removeItem('type');
                this.toastService.error('SESSION_EXPIRE');
                from(of('waitTime').pipe(delay(2000))).subscribe(() => {
                    this.router.navigate(['/', 'auth', 'login'], { queryParams: { url: this.currentPage } });
                    this.spinner.hide();
                });
                return throwError(refreshError);
            })
        );
    }

    private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

}
