<aside
    class="sidenav navbar navbar-vertical navbar-expand-xs fixed-start bg-white"
    id="sidenav-main"
    data-color="primary"
>
    <app-aside></app-aside>
</aside>
<main class="main-content position-relative border-radius-lg">
    <app-navbar></app-navbar>
    <div class="container-fluid py-4 bg-white h-100">
        <router-outlet></router-outlet>
    </div>
</main>
