import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';
import { I18nService, Logger } from '@app/core';
import { ToastComponent } from '@app/shared';
import { ToastService } from '@app/shared/toast/toast.services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
// declare const heap: any;

const log = new Logger('AppComponent');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    name = '';
    private componentInstance: ComponentRef<ToastComponent> = null;
    private currentUser: any = null;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private toastService: ToastService,
        private spinnerService: NgxSpinnerService,
        // private readonly authService: AuthService,
    ) {
        // heap.load(environment.HEAP_ID);
        // this.userModuleService.load();
        // mixpanel.init(environment.MIXPANEL.TOKEN);

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.spinnerService.show();
            } else if (event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError) {
                this.spinnerService.hide();
            }
        }, () => {
            this.spinnerService.hide();
        });

    }

    async ngOnInit() {
        /**
         * Fetch current toast message and display it using viewrefchild
         */
        this.toastService.currentToast.subscribe(message => {
            if (message[0].hasOwnProperty('type')) {
                if (!this.componentInstance) {
                    this.createToastComponent();
                    this.makeComponentAChild();
                }
            } else if (this.componentInstance) {
                this.componentInstance.destroy();
                this.componentInstance = null;
            }
        });

        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
        }

        let language = this.translateService.getBrowserCultureLang();

        if (environment.supportedLanguages.indexOf(language) === -1) {
            language = 'en-US';
        }

        // Setup translations
        this.i18nService.init(language, environment.supportedLanguages);
        // this.i18nService.language = language;
        const onNavigationEnd = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        // Change page title on navigation or language change, based on route data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                const title = event['title'];

                if (title) {
                    this.titleService.setTitle(
                        this.translateService.instant(title)
                    );
                }
            });
    }
    /**
     * It will create toast component by using ToastComponent which is in shared/toast directory
     */
    private createToastComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            ToastComponent
        );
        this.componentInstance = this.viewContainerRef.createComponent(
            componentFactory
        );
    }
    /**
     * It will set or make toast component as child of main container and add to any location in main container
     */
    private makeComponentAChild() {
        const toastComponentElement = this.componentInstance.location
            .nativeElement;
        const sibling: HTMLElement = toastComponentElement.previousSibling;
        sibling.insertBefore(toastComponentElement, sibling.firstChild);
    }
}
