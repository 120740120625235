import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '../../core';
import { ProfileServiceService } from '../../profile/profile-service.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { ToastService } from '../../shared/toast/toast.services';
import { StorageService } from '../../services/storage.service';
import { AuthService } from '../../auth/auth.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {

	userName: string;
	accountName: string;
	currentUser: any;

	constructor(
		private i18nService: I18nService,
		private readonly spinnerService: SpinnerService,
		private toastService: ToastService,
		private profileService: ProfileServiceService,
		private router: Router,
		private readonly authService: AuthService,
		private storageService: StorageService,
	) { }

	async ngOnInit(): Promise<void> {
		this.getProfile();
	}

	async getProfile() {
		this.spinnerService.show();

		this.profileService.get().subscribe({
			next: (response) => {
				this.spinnerService.hide();
				this.userName = response.firstName + ' ' + response.lastName;
				this.accountName = response.account.name
				localStorage.setItem('profile', response);
			},
			error: (error) => {
				console.error(error);
				this.toastService.error(error.message[0] || error.message);
				this.spinnerService.hide();
			}
		});
	}

	navigate() {
		this.router.navigate(['']);
	}

	setLanguage(language: string) {
		this.i18nService.language = language;
		this.i18nService.selectedLanguage.emit(language);
	}

	get currentLanguage(): string {
		return this.i18nService.language;
	}

	async logout() {
		this.authService.doLogout().subscribe({
			next: (response) => {
				this.spinnerService.show();
				this.storageService.removeItem('refreshToken');
				this.storageService.removeItem('accessToken');
				this.spinnerService.hide();
				return this.router.navigate(['/', 'auth', 'login']);
			}, error: (error) => {
				this.storageService.removeItem('refreshToken');
				this.storageService.removeItem('accessToken');
				console.error(error);
				this.spinnerService.hide();
				return this.router.navigate(['/', 'auth', 'login']);
			}
		})
	}
}
