import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {
	private loadingObject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isLoading = this.loadingObject.asObservable();
	public constructor() { }

	public show() {
		this.loadingObject.next(true);
	}

	public hide() {
		this.loadingObject.next(false);
	}
}
