import { AfterViewInit, Component } from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
})
export class LayoutComponent implements AfterViewInit {

    ngAfterViewInit(): void {
        // required to work some of argon2 features
        this.loadScripts();
    }

    loadScripts() {
        const script = document.createElement('script');
        script.src = 'assets/argon2/js/argon-dashboard.custom.js';
        script.defer = true;
        document.querySelector('body').appendChild(script);
    }
}
